const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer-Content">
        <p>&copy; Copyright 2022, Noah Corona </p>
      </div>
    </div>
  );
};

export default Footer;
